
export let Dropdown = (function() {


	function open(event) {


		let select = event.target.closest('[select]');
		let dropDown = event.target.closest('[select-drop]');
		let dropDownItem = event.target.closest('[select-drop] p');
		let selects = document.querySelectorAll('[select]');
		

		if (dropDownItem) {
			let title = event.target.closest('[select]').querySelector('[input-caption]');
			let input = event.target.closest('[select]').querySelector('input');

			title.innerHTML = dropDownItem.textContent;
			input.value = dropDownItem.textContent;

			input.dispatchEvent( new Event("change") );

			select.classList.toggle('is-open');
		}

		if (dropDown) {
			return;
		}
		if (!select || !select.classList.contains('is-open')) {

			allRemove(selects);

		}
		if (select) {

			select.classList.toggle('is-open');

		} else {

			allRemove(selects);

		}

	};

	function allRemove(selects) {
		[].forEach.call(selects, function(select) {
			select.classList.remove('is-open');
		});
	};

	function bindEvents() {
		document.addEventListener('click', open);
	}

	function init() {
		bindEvents();
	}
	return {
		init: init
	}

})();