import { Utils } from './utils';
import { ModalDispatcher } from './components/modalDispatcher';
import { TabsDispatcher } from './components/tabsDispatcher';
import { Dropdown } from './components/dropDown';
import { Swiper, Navigation, Scrollbar, Zoom, Mousewheel } from 'swiper/dist/js/swiper.esm.js';
import anime from 'animejs/lib/anime.es.js';
import SimpleBar from 'simplebar';

Swiper.use([ Swiper, Navigation, Scrollbar, Zoom, Mousewheel ]);

document.addEventListener('DOMContentLoaded', () => {

	var simpleBar = document.querySelectorAll('.simple_bar');
	[].forEach.call(simpleBar, function(bar) {

		new SimpleBar(bar);

	});


	//ripple

	[].map.call(document.querySelectorAll('[anim="ripple"]'), el=> {
		el.addEventListener('click',e => {
			e = e.touches ? e.touches[0] : e;
			const r = el.getBoundingClientRect(), d = Math.sqrt(Math.pow(r.width,2)+Math.pow(r.height,2)) * 2;
			el.style.cssText = `--s: 0; --o: 1;`;  el.offsetTop; 
			el.style.cssText = `--t: 1; --o: 0; --d: ${d}; --x:${e.clientX - r.left}; --y:${e.clientY - r.top};`
		})
	})

	//end

	//reviews

	new Swiper('.slider_reviews', {
		slidesPerView: 3,
		speed: 700,
		loop: true,
		spaceBetween: 20,
		mousewheelControl: true,
		freeModeMinimumVelocity: 0.02,
		mousewheelReleaseOnEdges: false,
		touchStartPreventDefault: false,
		mousewheel: {
			invert: true,
			enabled: true,
			forceToAxis: true
		},
		navigation: {
			nextEl: '.reviews__button--next',
			prevEl: '.reviews__button--prev',
		},
		breakpoints: {
			500: {
				slidesPerView: 1,
			}
		},
	});

	//end
	
	// checkHeader

	function checkHeader() {

		let scrollPosition = Math.round(window.scrollY);
		if (scrollPosition > 100){
			document.body.classList.add('sticky');
		} else {
			document.body.classList.remove('sticky');
		}
	};
	window.addEventListener('scroll', Utils.throttle(checkHeader, 25));
	
	// checkHeader end

	// inputValue


	let inputsForm = document.querySelectorAll('.form_input__input');
	[].forEach.call(inputsForm, function(input) {

		
		input.addEventListener('input', function(event) {
			if (input.validity.valid) {
				input.closest('.form_input').classList.remove('invalid');
				input.closest('.form_input').classList.add('valid');
			}
			else {
				input.closest('.form_input').classList.add('invalid');
				input.closest('.form_input').classList.remove('valid');
			}

			setTimeout(() => {	
				if(input.value) {
					input.classList.add('is-filled');
				} else {
					input.classList.remove('is-filled');
				}
			})

		});
	});

	//inputValue end

	

	let servicesTabs = document.querySelectorAll('[services-tab]');
	[].forEach.call(servicesTabs, function(tab) {
		tab.addEventListener('click', function() {
			tab.closest("[services-section]").classList.toggle('is-toggle');
		});
	});

	var linkNav = document.querySelectorAll('[href^="#"]'),//выбираем все ссылки к якорю на странице
	t = '',
	start = '',
	V = .4;  // скорость, может иметь дробное значение через точку (чем меньше значение - тем больше скорость)
	for (var i = 0; i < linkNav.length; i++) {
		linkNav[i].addEventListener('click', function(e) { //по клику на ссылку
			e.preventDefault();
			var w = window.pageYOffset, // производим прокрутка прокрутка
			hash = this.href.replace(/[^#]*(.*)/, '$1'); // к id элемента, к которому нужно перейти
			t = document.querySelector(hash).getBoundingClientRect().top, // отступ от окна браузера до id
			start = null;
			requestAnimationFrame(step);
			function step(time) {
				if (start === null) start = time;
				var progress = time - start,
				r = (t < 0 ? Math.max(w - progress/V, w + t) : Math.min(w + progress/V, w + t));
				window.scrollTo(0,r);
				if (r != w + t) {
					requestAnimationFrame(step)
				} else {
					location.hash = hash // URL с хэшем
				}
			}
		}, false);
	};


	let PeoplesCounter = (function() {

		function listener() {
			let el = event.target.closest('[data-button]');
			if (!el) return;

			if (el.dataset.line === 'old') {
				setCounter(el);
				let counterContainer = el.closest('.form_peoples_line__buttons').querySelector('.form_peoples_line__counter'),
					 counter = counterContainer.textContent;
				if (counter >= 2) {
					unlockButtons(el);
				}
				if (counter <= 1) {
					lockButtons(el);
				}
			}

			if (el.dataset.line === 'children') {
				setCounter(el);
				let counterContainer = el.closest('.form_peoples_line__buttons').querySelector('.form_peoples_line__counter'),
					 counter = counterContainer.textContent;
				if (counter >= 1) {
					unlockButtons(el);
				}
				if (counter < 1) {
					lockButtons(el);
				}
			}
			if (el.dataset.line === 'baby') {
				setCounter(el);
				let counterContainer = el.closest('.form_peoples_line__buttons').querySelector('.form_peoples_line__counter'),
					 counter = counterContainer.textContent;
				if (counter >= 1) {
					unlockButtons(el);
				}
				if (counter < 1) {
					lockButtons(el);
				}
			}

		};

		function unlockButtons(el) {
			let buttonsContainer = el.closest('.form_peoples_line__buttons'),
				 disableButton = buttonsContainer.querySelector('[data-button="minus"]');

			disableButton.classList.remove('disable')
		}

		function lockButtons(el) {
			let buttonsContainer = el.closest('.form_peoples_line__buttons'),
				 disableButton = buttonsContainer.querySelector('[data-button="minus"]');

			disableButton.classList.add('disable')
		}

		function setCounter(el) {
			let counterContainer = el.closest('.form_peoples_line__buttons').querySelector('.form_peoples_line__counter');
			let counter = counterContainer.textContent;
			let current = '';
			let input = el.closest('[select]').querySelector('[name="peoples"]');
			let inputValue = input.value;
			if (el.dataset.button === "plus") {
				current = parseInt(counter) + 1;
				inputValue = parseInt(inputValue) + 1;
			}
			else if (el.dataset.button === "minus") {
				current = counter - 1;
				inputValue = inputValue - 1;
			}
			counterContainer.innerHTML = current;
			input.setAttribute('value', inputValue);
			el.closest('[select]').querySelector('[input-caption] span').innerHTML = inputValue;


			
		}


		function bindEvents() {
			document.addEventListener('click', listener);
		}

		function init() {
			bindEvents();
		}
		return {
			init: init
		}

	})();


	let myCalendarDate = new VanillaCalendar({
		selector: "#myCalendar1",
		months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
		shortWeekday: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС']
	});

	let myCalendarStart = new VanillaCalendar({
		selector: "#myCalendar2",
		months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
		shortWeekday: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС']
	});

	let myCalendarEnd = new VanillaCalendar({
		selector: "#myCalendar3",
		months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
		shortWeekday: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС']
	});


	let DateSelect = (function() {

		function listener() {
			let el = event.target.closest('.vanilla-calendar-date');
			if (!el) return;
			let input = el.closest('[select]').querySelector('input'),
				 date = el.closest('[select]').querySelector('[input-caption]'),
				 day = el.textContent,
				 month = el.closest('.vanilla-calendar').querySelector('.vanilla-calendar-header__label').textContent;
			date.textContent = day + ' ' + month;
			date.classList.add('is-filled')

			
			input.value = day + ' ' + month;
		};


		function bindEvents() {
			document.addEventListener('click', listener);
		}

		function init() {
			bindEvents();
		}
		return {
			init: init
		}

	})();




	DateSelect.init();
	PeoplesCounter.init();
	Dropdown.init();
	TabsDispatcher.init();
	ModalDispatcher.init();


	window.addEventListener('submit', function(event) {
		
		var form = event.target.closest('[data-request]');
		if (!form) return;
		event.preventDefault();
		form.classList.add('on-request');
		var data = new FormData(form);
		fetch("/mailer.php", {
			method: 'POST',
			body: data
		}).then(function(r) {
			
			return r.text();
			
		}).then(function(){
			var event =  new Event('mousedown');
			form.classList.remove('on-request');
			event.which = 1;
			if (document.querySelector('#commonModalOverlay').classList.contains('is-active')) {
				document.querySelector('#commonModalOverlay').dispatchEvent(event);
			}
			document.dispatchEvent(new CustomEvent('needModal', {detail: 'accept_feedback'}));
			
		});
	});


})


